<template>
  <v-sheet
    class="grey lighten-3 pa-4 my-3"
    rounded="lg"
  >
    <v-row dense>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="contact.name"
          label="Nome completo"
          placeholder="Nome completo"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="contact.email"
          label="Email"
          placeholder="Email"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="contact.department"
          label="Departamento"
          placeholder="Departamento"
          outlined
          rounded
          dense
          hide-details
        />
      </v-col>
      <v-col
        v-for="(phone, idx) in contact.phones"
        :key="idx"
        cols="12"
        md="4"
      >
        <v-text-field
          v-model="phone.number"
          v-mask="['(##) ####-####','(##) #####-####']"
          :label="`Telefone ${++idx}`"
          placeholder="(xx) xxxxx-xxxx"
          append-icon="fas fa-trash"
          outlined
          rounded
          dense
          hide-details
          @click:append="removePhone(phone)"
        />
      </v-col>
      <v-col
        v-if="contact.phones.length < 3"
        cols="12"
        md="4"
      >
        <v-btn
          class="primary"
          icon
          dark
          @click="addPhone"
        >
          <v-icon small>
            fas fa-plus
          </v-icon>
        </v-btn>
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <v-btn
          outlined
          rounded
          color="primary"
          @click="$emit('removeContact')"
        >
          Remover Contato
        </v-btn>
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
export default {
  props: {
    contact: {
      type: Object,
      default: () => ({})
    }
  },
  methods: {
    addPhone() {
      this.contact.phones.push({})
    },
    removePhone(phone) {
      let idx = this.contact.phones.indexOf(phone)
      this.contact.phones.splice(idx, 1)
    }
  },
}
</script>